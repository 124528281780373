<script>
import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'

export default {
  name: 'UploadDocumentIsOptionalListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'fas fa-ellipsis-h',
      itemName: 'optional document',
      placeholder: 'Select an option',
      hideLabel: true,
      searchLength: 3
    }
  },
  computed: {
    listOptions () {
      return [{
        id: 'True',
        name: this.$t('Optional')
      },
      {
        id: 'False',
        name: this.$t('Not Optional')
      }]
    }
  },
  methods: {
    onSearchItem (search) {},
    getComponentFilter () {},
    addFilter (item) {
      const componentList = []
      componentList.push(item)
      this.$set(this, 'componentList', componentList)
      this.$emit('edit', [item.id])
    }
  }
}
</script>
