<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as COUNTRY_TYPES } from '../../l10n/country/store'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'

export default {
  name: 'AdministrativeAreaListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'far fa-paper-plane',
      itemName: 'administrative area',
      itemPluralName: 'administrative areas',
      placeholder: 'Type an administrative area',
      searchLength: 3
    }
  },
  computed: {
    ...mapGetters({
      administrativeAreaList: COUNTRY_TYPES.GENERIC.l10n.country.LIST.GETTERS.administrativeAreaList
    }),
    listOptions () {
      const options = []
      if (this.administrativeAreaList && this.administrativeAreaList.length > 0) {
        this.administrativeAreaList.forEach((continent) => {
          options.push({
            id: continent[0],
            name: continent[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      setFilters:
        COUNTRY_TYPES.GENERIC.l10n.country.LIST.ACTIONS.getAdministrativearealist
    }),
    onSearchItem (search) {}
  }
}
</script>
