<template>
  <b-card
    class="accordion"
    :class="visible ? `${classes} accordion-open` : classes"
    no-body
  >
    <b-button variant="primary" @click.prevent="visible = !visible">
      <h5>
        <span v-if="icon" class="mr-1"><i :class="icon" /></span>
        <span>{{ title }}</span>
        <span v-if="active" class="fas fa-circle status-filter" />
      </h5>
      <div class="collapse-icon"></div>
    </b-button>
    <b-collapse :id="refId" v-model="visible">
      <b-card-body>
        <slot name="accordion-body"></slot>
      </b-card-body>
      <b-card-footer v-if="hasSlot('accordion-footer')">
        <slot name="accordion-footer"></slot>
      </b-card-footer>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    title: {},
    icon: {},
    classes: { default: '' },
    startOpen: { default: false },
    active: {}
  },
  data () {
    return {
      refId: undefined,
      visible: false
    }
  },
  created () {
    if (this.startOpen) this.$set(this, 'visible', true)
    this.refId = this.generateUUID()
  },
  methods: {
    hasSlot (name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name]
    }
  }
}
</script>
