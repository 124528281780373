<script>
import { mapActions, mapGetters } from 'vuex'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'
import { TYPES as DELIVERY_TYPES } from '../../carriage/delivery/store'

export default {
  name: 'TransportByListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'far fa-paper-plane',
      itemName: 'transport by',
      itemPluralName: 'transports by',
      placeholder: 'Type a transport by',
      searchLength: 3
    }
  },
  computed: {
    ...mapGetters({
      list: DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.GETTERS.transportByList
    }),
    listOptions () {
      const options = []
      if (this.list && this.list.length > 0) {
        this.list.forEach((transporterBy) => {
          options.push({
            id: transporterBy[0],
            name: transporterBy[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      setFilters: DELIVERY_TYPES.GENERIC.carriage.delivery.LIST.ACTIONS.getTransportByList
    }),
    onSearchItem (search) {}
  }
}
</script>
