<template>
  <accordion
    v-if="canSeeAccordion"
    :title="title ? title : $t(`Filter by ${itemName}`)"
    :icon="icon"
    :active="componentList.length > 0"
    :start-open="visible ? visible : false"
    classes="filter-accordion small mb-3"
  >
    <template slot="accordion-body">
      <div v-if="componentList.length !== 0" class="filters-active">
        <span>{{ 'Active filters' | translate }}</span>
        <b-button
          variant="outline-primary"
          class="btn-sm"
          @click.prevent="cleanComponentFilters()"
        >
          <i class="fas fa-times" /> {{ 'Reset filter' | translate }}
        </b-button>
      </div>
      <b-alert class="alert-primary" v-for="row in componentList" :key="row.id" variant="filter" show>
        <span>{{ row.label | truncate(35) }}</span>
        <span class="close" @mousedown.prevent.stop="removeFilter(row)">
          <i class="fas fa-times" :title="'Delete' | translate" />
        </span>
      </b-alert>
      <multiselect-thux
        label="label"
        attributetype="common filter select"
        :value="componentList[0]"
        :options="listOptions"
        :placeholder="$t('Select an option')"
        :show-clean-button="false"
        @select="addFilter"
      >
        <template v-if="customizeOption" slot="option" slot-scope="data">
          <div class="custom-option" v-html="getCustomOption(data)"></div>
        </template>
      </multiselect-thux>
    </template>
  </accordion>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as INVOICE_TYPE } from '../../invoice/invoice/store'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'

export default {
  name: 'InvoiceTypologyFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'fas fa-receipt',
      itemName: 'invoice type',
      itemPluralName: 'invoice types',
      placeholder: 'Type an invoice type',
      searchLength: 3
    }
  },
  computed: {
    ...mapGetters({
      invoiceTypologyChoicesList: INVOICE_TYPE.GENERIC.invoice.invoice.LIST.GETTERS.invoiceTypologyChoicesList
    }),
    listOptions () {
      const options = []
      if (this.invoiceTypologyChoicesList && this.invoiceTypologyChoicesList.length > 0) {
        this.invoiceTypologyChoicesList.forEach((invType) => {
          options.push({
            id: invType[0],
            label: invType[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getInvoicetypologychoiceslist:
        INVOICE_TYPE.GENERIC.invoice.invoice.LIST.ACTIONS.getInvoicetypologychoiceslist
    }),
    getComponentFilter () {
      this.getInvoicetypologychoiceslist()
    },
    addFilter (item) {
      const componentList = []
      componentList.push(item)
      this.$set(this, 'componentList', componentList)
      this.$emit('edit', [item.id])
    }
  }
}
</script>
