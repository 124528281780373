<script>
import { mapGetters, mapActions } from 'vuex'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'
import { CommonFilterTypes } from '../store'

export default {
  name: 'CarriageListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'far fa-paper-plane',
      itemName: 'carriage',
      itemPluralName: 'carriages',
      placeholder: 'Type a carriage',
      filterName: 'filter__name__icontains',
      searchLength: 1
    }
  },
  computed: {
    ...mapGetters({
      list: CommonFilterTypes.COMMON_FILTERS.CARRIAGE_COMMON_FILTER.LIST.GETTERS.list
    }),
    listOptions () {
      const options = []
      if (this.list && this.list.length > 0) {
        this.list.forEach((carriage) => {
          options.push({
            id: carriage.code,
            name: this.$t(carriage.name)
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      ...CommonFilterTypes.COMMON_FILTERS.CARRIAGE_COMMON_FILTER.LIST.ACTIONS
    }),
    onSearchItem (search) {},
    getComponentFilter () {
      this.setFilters({ no_page: 'no_page' })
    }
  }
}
</script>
