<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as PRODUCT_CATEGORY_TYPES } from '../../product/product-category/store'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'

export default {
  name: 'TypeOfSaleChoicesListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: '',
      itemName: 'type of sale',
      itemPluralName: 'types of sale',
      placeholder: 'Enter a type of sale',
      searchLength: 3
    }
  },
  computed: {
    ...mapGetters({
      typeOfSaleChoicesList: PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.GETTERS.typeOfSaleChoices
    }),
    listOptions () {
      const options = []
      if (this.typeOfSaleChoicesList && this.typeOfSaleChoicesList.length > 0) {
        this.typeOfSaleChoicesList.forEach((typeOfSale) => {
          options.push({
            id: typeOfSale[0],
            name: typeOfSale[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getTypeOfSaleChoicesList:
        PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.ACTIONS.getTypeofsalechoices
    }),
    onSearchItem (search) {},
    getComponentFilter () {
      this.getTypeOfSaleChoicesList()
    },
    addFilter (item) {
      const componentList = []
      componentList.push(item)
      this.$set(this, 'componentList', componentList)
      this.$emit('edit', [item.id])
    }
  }
}
</script>
