<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as COUNTRY_TYPES } from '../../l10n/country/store'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'

export default {
  name: 'ContinentListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'far fa-paper-plane',
      itemName: 'continent',
      itemPluralName: 'continents',
      placeholder: 'Type a continent',
      searchLength: 3
    }
  },
  computed: {
    ...mapGetters({
      continentList: COUNTRY_TYPES.GENERIC.l10n.country.LIST.GETTERS.continentList
    }),
    listOptions () {
      const options = []
      if (this.continentList && this.continentList.length > 0) {
        this.continentList.forEach((continent) => {
          options.push({
            id: continent[0],
            name: continent[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      setFilters:
        COUNTRY_TYPES.GENERIC.l10n.country.LIST.ACTIONS.getContinentlist
    }),
    onSearchItem (search) {}
  }
}
</script>
