<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { CommonFilterTypes } from '../store'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'

export default {
  name: 'OrganizationGroupListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'far fa-paper-plane',
      itemName: 'category',
      itemPluralName: 'categories',
      placeholder: 'Type a category',
      searchLength: 2
    }
  },
  computed: {
    ...mapGetters({
      list: CommonFilterTypes.COMMON_FILTERS.ORGANIZATION_GROUP_COMMON_FILTER.LIST.GETTERS.list
    })
  },
  methods: {
    ...mapMutations({
      setList:
        CommonFilterTypes.COMMON_FILTERS.ORGANIZATION_GROUP_COMMON_FILTER.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      ...CommonFilterTypes.COMMON_FILTERS.ORGANIZATION_GROUP_COMMON_FILTER.LIST.ACTIONS
    }),
    getComponentFilter () {
      this.setList({})
    }
  }
}
</script>
