<script>
import { mapGetters, mapMutations } from 'vuex'
import { CommonFilterTypes } from './store'
import ThuxCommonFilterMixin from '../../components/thux-list/ThuxCommonFilterMixin'

import AdministrativeAreaListFilter from './administrative-area-filter/AdministrativeAreaListFilter'
import AnalysisTypeChoicesListFilter from './analysis-type-choices-filter/AnalysisTypeChoicesListFilter'
import CarriageListFilter from './carriage-filter/CarriageListFilter'
import ContactListFilter from './contact-filter/ContactListFilter'
import ContinentListFilter from './continent-filter/ContinentListFilter'
import CustomerContactListFilter from './customer-contact-filter/CustomerContactListFilter'
import DocumentTypeListFilter from './document-type-filter/DocumentTypeListFilter'
import InvoiceStatusListFilter from './invoice-status/InvoiceStatusListFilter'
import OpenCloseProposalListFilter from './open-close-filter/OpenCloseProposalListFilter'
import OrderDoneListFilter from './order-done-filter/OrderDoneListFilter'
import OrganizationAddressListFilter from './organization-address-filter/OrganizationAddressListFilter'
import OrganizationGroupListFilter from './organization-group-filter/OrganizationGroupListFilter'
import OrganizationListFilter from './organization-filter/OrganizationListFilter'
import OwnerContactListFilter from './owner-contact-filter/OwnerContactListFilter'
import ProductCategoryListFilter from './product-category-filter/ProductCategoryListFilter'
import RoleListFilterVue from './role-filter/RoleListFilter'
import SecurityRoleListFilter from './security-role-filter/SecurityRoleListFilter'
import StatusListFilter from './status-filter/StatusListFilter'
import SubmittedListFilter from './submitted-filter/SubmittedListFilter'
import TransportByListFilter from './transport-by-filter/TransportByListFilter'
import TypeOfSaleChoicesListFilter from './type-of-sale-choices-filter/TypeOfSaleChoicesListFilter'
import WorkStatusChoicesListFilter from './work-status-choice-filter/WorkStatusChoicesListFilter'
import ChecklistListFilter from './checklist-filter/ChecklistListFilter'
import UploadDocumentStatusChoicesListFilter from './upload-document-status-choice-filter/UploadDocumentStatusChoicesListFilter'
import UploadDocumentIsOptionalListFilter from './upload-document-is-optional-filter/UploadDocumentIsOptionalListFilter'
import YardListFilter from './yard-filter/YardListFilter'
import InvoiceTypologyFilter from './invoice-typology/InvoiceTypologyFilter'

export default {
  name: 'CommonFiltersMixin',
  mixins: [ThuxCommonFilterMixin],
  components: {
    'administrative-area-list-filter': AdministrativeAreaListFilter,
    'analysis-type-choices-list-filter': AnalysisTypeChoicesListFilter,
    'carriage-list-filter': CarriageListFilter,
    'contact-list-filter': ContactListFilter,
    'continent-list-filter': ContinentListFilter,
    'customer-contact-list-filter': CustomerContactListFilter,
    'document-type-list-filter': DocumentTypeListFilter,
    'invoice-status-list-filter': InvoiceStatusListFilter,
    'open-close-proposal-list-filter': OpenCloseProposalListFilter,
    'order-done-list-filter': OrderDoneListFilter,
    'organization-address-list-filter': OrganizationAddressListFilter,
    'organization-group-list-filter': OrganizationGroupListFilter,
    'organization-list-filter': OrganizationListFilter,
    'owner-contact-list-filter': OwnerContactListFilter,
    'product-category-list-filter': ProductCategoryListFilter,
    'role-list-filter': RoleListFilterVue,
    'security-role-list-filter': SecurityRoleListFilter,
    'status-list-filter': StatusListFilter,
    'submitted-list-filter': SubmittedListFilter,
    'transport-by-list-filter': TransportByListFilter,
    'type-of-sale-choices-list-filter': TypeOfSaleChoicesListFilter,
    'work-status-choice-filter': WorkStatusChoicesListFilter,
    'checklist-list-filter': ChecklistListFilter,
    'upload-document-status-choices-list-filter': UploadDocumentStatusChoicesListFilter,
    'upload-document-is-optional-list-filter': UploadDocumentIsOptionalListFilter,
    'yard-list-filter': YardListFilter,
    'invoice-typology-filter': InvoiceTypologyFilter
  },
  computed: {
    ...mapGetters({
      reset: CommonFilterTypes.COMMON_FILTERS.GETTERS.reset
    })
  },
  methods: {
    ...mapMutations({
      setReset: CommonFilterTypes.COMMON_FILTERS.MUTATIONS.setReset
    })
  }
}
</script>
