<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as YARD_TYPES } from '../../yard/yard/store'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'

export default {
  name: 'WorkStatusChoicesListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'fas fa-ellipsis-h',
      itemName: 'status of work',
      itemPluralName: 'statuses of work',
      placeholder: 'Select an option',
      searchLength: 3
    }
  },
  computed: {
    ...mapGetters({
      workStatusChoicesList: YARD_TYPES.GENERIC.yard.yard.LIST.GETTERS.workStatusChoices
    }),
    listOptions () {
      const options = []
      if (this.workStatusChoicesList && this.workStatusChoicesList.length > 0) {
        this.workStatusChoicesList.forEach((workStatus) => {
          options.push({
            id: workStatus[0],
            name: workStatus[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getWorkStatusChoicesList:
        YARD_TYPES.GENERIC.yard.yard.LIST.ACTIONS.getWorkstatuschoices
    }),
    onSearchItem (search) {},
    getComponentFilter () {
      this.getWorkStatusChoicesList()
    },
    addFilter (item) {
      const componentList = []
      componentList.push(item)
      this.$set(this, 'componentList', componentList)
      this.$emit('edit', [item.id])
    }
  }
}
</script>
