<template>
  <accordion
    v-if="canSeeAccordion"
    :title="title ? title : $t(`Filter by ${itemName}`)"
    :icon="icon"
    :active="componentList.length > 0"
    :start-open="visible ? visible : false"
    classes="filter-accordion small mb-3"
  >
    <template slot="accordion-body">
      <div v-if="componentList.length !== 0" class="filters-active">
        <span>{{ 'Active filters' | translate }}</span>
        <b-button
          variant="outline-primary"
          class="btn-sm"
          @click.prevent="cleanComponentFilters()"
        >
          <i class="fas fa-times" /> {{ 'Reset filter' | translate }}
        </b-button>
      </div>
      <b-alert class="alert-primary" v-for="row in componentList" :key="row.id" variant="filter" show>
        <span>{{ row[label] | truncate(35) }}</span>
        <span class="close" @mousedown.prevent.stop="removeFilter(row)">
          <i class="fas fa-times" :title="'Delete' | translate" />
        </span>
      </b-alert>
      <multiselect-thux
        attributetype="common filter"
        :label="label"
        :message="!hideLabel ?
            componentList.length === 1
              ? $t(`${itemName} selected`)
              : $t(`${itemPluralName} selected`) : null
        "
        :show-clean-button="false"
        :customize-option="customizeOption"
        :value="componentList"
        :options="listOptions"
        :placeholder="$t(placeholder)"
        :min-length="searchLength"
        @search="onSearchItem"
        @select="addFilter"
      >
        <template v-if="customizeOption" slot="option" slot-scope="data">
          <div class="custom-option" v-html="getCustomOption(data)"></div>
        </template>
      </multiselect-thux>
    </template>
  </accordion>
</template>

<script>
import { vue } from '../../main'

import Accordion from '../Accordion'
import MultiselectThux from '../MultiselectThux'
import { mapGetters } from 'vuex'
import { CommonFilterTypes } from '../../pages/common-filters/store'

export default {
  name: 'ThuxGenericComponentFilterMixin',
  props: ['title', 'visible'],
  components: {
    accordion: Accordion,
    'multiselect-thux': MultiselectThux
  },
  data () {
    return {
      hideLabel: false,
      label: 'name',
      filterName: '',
      icon: null,
      searchLength: 3,
      itemName: '',
      itemPluralName: '',
      placeholder: '',
      showAdvancedSearch: false,
      showTable: true,
      pageToJump: undefined,
      componentList: [],
      customizeOption: true
    }
  },
  watch: {
    reset: function () {
      this.clearFields()
    },
    componentList () {
      this.$emit('set-is-active', this.componentList.length > 0)
    }
  },
  created () {
    this.getComponentFilter()
  },
  computed: {
    ...mapGetters({
      reset: CommonFilterTypes.COMMON_FILTERS.GETTERS.reset
    }),
    canSeeAccordion () {
      return true
    },
    listOptions () {
      const options = []
      const list = this.list && this.list.results ? this.list.results : this.list
      if (list && list.length > 0) {
        list.forEach((instance) => {
          options.push({
            id: instance.id,
            name: instance.name,
            status: instance.status
          })
        })
      }
      return options
    }
  },
  methods: {
    getComponentFilter () {
      this.setFilters()
    },
    resetFields () {
      this.clearFields()
      this.$emit('clean')
    },
    addFilter (item) {
      const componentList = [...this.componentList]
      componentList.push(item)
      this.$set(this, 'componentList', componentList)
      this.$emit(
        'edit',
        componentList.map((row) => row.id)
      )
    },
    removeFilter (item) {
      const componentList = this.componentList.filter((instance) => {
        return instance.id !== item.id
      })
      this.$set(this, 'componentList', componentList)
      if (this.componentList.length === 0) {
        this.cleanComponentFilters()
      } else {
        this.$emit(
          'edit',
          this.componentList.map((row) => row.id)
        )
      }
    },
    onSearchItem (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= this.searchLength) {
        const filters = {}
        filters[this.filterName] = search
        filters.order_by = '-status.-date_create'
        this.setFilters(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    clearFields () {
      this.$set(this, 'componentList', [])
    },
    cleanComponentFilters () {
      this.resetFields()
    },
    getCustomOption (data) {
      const option = data.item.option
      let statusIcon = ''
      let optionClasses = ''
      if (option.status === 0) {
        statusIcon = '<i class="far fa-times-circle text-red mr-2"></i>'
        optionClasses = 'option-disabled'
      }
      return `${statusIcon} <span class="${optionClasses}">${option.name}</span>`
    }
  }
}
</script>
