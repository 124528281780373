<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from '@/pages/document/upload-document/store'
import ThuxGenericComponentFilterMixin from '@/components/thux-list/ThuxGenericComponentFilterMixin'

export default {
  name: 'UploadDocumentStatusChoicesListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'far fa-circle',
      itemName: 'status of document',
      itemPluralName: 'statuses of document',
      placeholder: 'Select an option',
      searchLength: 3
    }
  },
  computed: {
    ...mapGetters({
      documentStatusChoicesList: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.documentStatusChoices
    }),
    listOptions () {
      const options = []
      if (this.documentStatusChoicesList && this.documentStatusChoicesList.length > 0) {
        this.documentStatusChoicesList.forEach((documentStatus) => {
          options.push({
            id: documentStatus[0],
            name: documentStatus[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getDocumentStatusChoicesList:
        UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.getDocumentstatuschoices
    }),
    onSearchItem (search) {},
    getComponentFilter () {
      this.getDocumentStatusChoicesList()
    },
    addFilter (item) {
      const componentList = []
      componentList.push(item)
      this.$set(this, 'componentList', componentList)
      this.$emit('edit', [item.id])
    }
  }
}
</script>
