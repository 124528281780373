<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as PROPOSAL_TYPES } from '../../proposal/proposal/store'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'

export default {
  name: 'AnalysisTypeChoicesListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'far fa-id-card',
      itemName: 'analysis type',
      itemPluralName: 'analysis type',
      placeholder: 'Type an analysis type',
      searchLength: 3
    }
  },
  computed: {
    ...mapGetters({
      analysisTypeChoicesList: PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.GETTERS.analysisTypeChoicesList
    }),
    listOptions () {
      const options = []
      if (this.analysisTypeChoicesList && this.analysisTypeChoicesList.length > 0) {
        this.analysisTypeChoicesList.forEach((analysisType) => {
          options.push({
            id: analysisType[0],
            name: analysisType[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getAnalysisTypeChoicesList:
        PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.ACTIONS.getAnalysistypechoiceslist
    }),
    onSearchItem (search) {},
    getComponentFilter () {
      this.getAnalysisTypeChoicesList()
    },
    addFilter (item) {
      const componentList = []
      componentList.push(item)
      this.$set(this, 'componentList', componentList)
      this.$emit('edit', [item.id])
    }
  }
}
</script>
