<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'
import { CommonFilterTypes } from '../store'

export default {
  name: 'YardListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'far fa-building',
      itemName: 'yard',
      itemPluralName: 'yards',
      placeholder: 'Type a yard',
      filterName: 'filter__code__icontains',
      searchLength: 3
    }
  },
  computed: {
    ...mapGetters({
      list: CommonFilterTypes.COMMON_FILTERS.YARD_COMMON_FILTER.LIST.GETTERS.list
    }),
    listOptions () {
      const options = []
      const list = this.list && this.list.results ? this.list.results : this.list
      if (list && list.length > 0) {
        list.forEach((instance) => {
          options.push({
            id: instance.id,
            name: instance.code,
            status: instance.status
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setList:
        CommonFilterTypes.COMMON_FILTERS.YARD_COMMON_FILTER.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      ...CommonFilterTypes.COMMON_FILTERS.YARD_COMMON_FILTER.LIST.ACTIONS
    }),
    getComponentFilter () {
      this.setList({})
    }
  }
}
</script>
