<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'
import { CommonFilterTypes } from '../store'

export default {
  name: 'SecurityRoleListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'fas fa-user-lock',
      itemName: 'security role',
      itemPluralName: 'security roles',
      placeholder: 'Type a security role',
      filterName: 'filter__name__icontains',
      searchLength: 1
    }
  },
  computed: {
    ...mapGetters({
      list: CommonFilterTypes.COMMON_FILTERS.SECURITY_ROLE_COMMON_FILTER.LIST.GETTERS.list
    })
  },
  methods: {
    ...mapMutations({
      setList:
        CommonFilterTypes.COMMON_FILTERS.SECURITY_ROLE_COMMON_FILTER.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      ...CommonFilterTypes.COMMON_FILTERS.SECURITY_ROLE_COMMON_FILTER.LIST.ACTIONS
    }),
    getComponentFilter () {
      this.setList({})
    }
  }
}
</script>
