<script>
import moment from 'moment'

export default {
  name: 'ThuxCommonFiltersMixin',
  methods: {
    setFilterToComponent (filterName, filter, filterToRemove) {
      const filters = this.filters ? Object.assign({}, this.filters) : {}
      if (Array.isArray(filterName)) {
        delete filters[filterName[0]]
        delete filters[filterName[1]]
        const filterNameDate = filterName[0].replace('__gte', '')
        delete filters[filterNameDate]
        const isDifferentDate =
          moment(new Date(filter[0])).format('YYYY-MM-DD') !==
          moment(new Date(filter[1])).format('YYYY-MM-DD')
        if (filter.length === 2 && isDifferentDate) {
          filters[filterName[0]] = moment(new Date(filter[0])).format(
            'YYYY-MM-DD'
          )
          filters[filterName[1]] = moment(new Date(filter[1])).format(
            'YYYY-MM-DD'
          )
        } else {
          filters[filterNameDate] = moment(filter[0]).format('YYYY-MM-DD')
        }
      } else if (Array.isArray(filter)) {
        filters[filterName] = filter.join(',')
      } else {
        filterName = filterName.replace('__gte', '')
        delete filters[filterName]
        filters[filterName] = filter
      }
      if (filterToRemove) {
        delete filters[filterToRemove]
      }
      this.setFilters(filters)
    },
    editComponentFilters (filterList, componentFilters, filterToRemove) {
      if (filterList) {
        this.setFilterToComponent(componentFilters, filterList, filterToRemove)
      } else {
        this.cleanComponentFilters(componentFilters)
      }
    },
    cleanComponentFilters (componentFilters) {
      this.removeFilters(componentFilters)
    },
    resetFilters () {
      this.setReset(this.filters)
    }
  }
}
</script>
