<template>
  <accordion
    v-if="canSeeAccordion"
    :title="title ? title : $t(`Filter by ${itemName}`)"
    :icon="icon"
    :active="componentList.length > 0"
    :start-open="visible ? visible : false"
    classes="filter-accordion small mb-3"
  >
    <template slot="accordion-body">
      <div v-if="componentList.length !== 0" class="filters-active">
        <span>{{ 'Active filters' | translate }}</span>
        <b-button
          variant="outline-primary"
          class="btn-sm"
          @click.prevent="cleanComponentFilters()"
        >
          <i class="fas fa-times" /> {{ 'Reset filter' | translate }}
        </b-button>
      </div>
      <b-alert class="alert-primary" v-for="row in componentList" :key="row.id" variant="filter" show>
        <span>{{ row.label | truncate(35) }}</span>
        <span class="close" @mousedown.prevent.stop="removeFilter(row)">
          <i class="fas fa-times" :title="'Delete' | translate" />
        </span>
      </b-alert>
      <multiselect-thux
        label="label"
        attributetype="common filter select"
        :value="componentList[0]"
        :options="listOptions"
        :placeholder="$t('Select an option')"
        :show-clean-button="false"
        @select="addFilter"
      >
        <template v-if="customizeOption" slot="option" slot-scope="data">
          <div class="custom-option" v-html="getCustomOption(data)"></div>
        </template>
      </multiselect-thux>
    </template>
  </accordion>
</template>

<script>

import ThuxGenericComponentFilterMixin from '../../../components/thux-list/ThuxGenericComponentFilterMixin'

export default {
  name: 'OpenCloseProposalListFilter',
  mixins: [ThuxGenericComponentFilterMixin],
  data () {
    return {
      icon: 'fas fa-lock',
      itemName: 'state of processing'
    }
  },
  computed: {
    listOptions () {
      return [
        { id: 'False', label: this.$t('OPENED'), status: 1 },
        { id: 'True', label: this.$t('CLOSED'), status: 1 }
      ]
    }
  },
  methods: {
    getComponentFilter () {},
    addFilter (item) {
      const componentList = []
      componentList.push(item)
      this.$set(this, 'componentList', componentList)
      this.$emit('edit', [item.id])
    }
  }
}
</script>
